var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'self-service-footer': 1,
      'footer-desktop': !_vm.mobileView,
      'footer-mobile': _vm.mobileView
    }
  }, [_vm.broker ? _c('div', {
    staticClass: "footer-broker-card"
  }, [_vm.broker[0].profilePicture !== null ? _c('div', {
    staticClass: "broker-img"
  }, [_c('img', {
    staticClass: "broker-img-src",
    attrs: {
      "src": _vm.broker[0].profilePicture.name || _vm.broker[0].profilePicture[0].name
    }
  })]) : _c('div', {
    staticClass: "empty-broker-img"
  }), _c('div', {
    staticClass: "footer-broker-info"
  }, [_c('div', {
    staticClass: "broker-info-text"
  }, [_c('span', {
    staticClass: "broker-name"
  }, [_vm._v(" " + _vm._s(_vm.broker[0].name) + " ")]), _c('div', {
    staticClass: "broker-contact",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.callNumber.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.broker[0].phoneNumber) + " ")])]), _c('div', {
    staticClass: "broker-contact",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.sendEmail.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.broker[0].email) + " ")])])])])]) : _vm._e(), _c('div', {
    staticClass: "send-message-button",
    staticStyle: {
      "display": "none"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/chat.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-semibold",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Send")) + " " + _vm._s(_vm.$t("Message").toLowerCase()) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }