var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('v-row', {
    staticClass: "first-row"
  }, [_vm.isPreview ? _c('h3', {
    staticClass: "header-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClientReport")) + " ")]) : _vm._e(), _vm.deal && !_vm.isPreview ? _c('h3', {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.deal.name))]) : _vm._e(), !_vm.deal && _vm.deals && !_vm.isPreview ? _c('h3', {
    staticClass: "header-title"
  }, [_vm._v(" " + _vm._s(_vm.deals[0].name) + " ")]) : _vm._e(), _vm.isPreview && _vm.projectClient.isPublished ? _c('div', {
    staticClass: "published-status"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Published")))])]) : _vm._e(), _vm.isPreview && !_vm.projectClient.isPublished ? _c('div', {
    staticStyle: {
      "height": "34px"
    }
  }, [_c('button', {
    staticClass: "self-service-button",
    attrs: {
      "name": "self-service-button",
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.sendInvitation();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Publish")) + " ")])]) : _vm._e()]), !_vm.isPreview ? _c('div', {
    staticStyle: {
      "padding-top": "12px"
    }
  }, [_vm.deals ? _c('Dropdown', {
    attrs: {
      "items": _vm.deals,
      "display-key": 'name',
      "value-key": '_id',
      "default-item": _vm.deals[0],
      "selected": _vm.deals[0]
    },
    on: {
      "itemSelected": _vm.getDealListings
    }
  }) : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }