var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('SelfServiceTopBar', {
    attrs: {
      "is-preview": _vm.isPreview
    }
  }), _c('Header', {
    attrs: {
      "deals": _vm.deals,
      "is-preview": _vm.isPreview,
      "project-client": _vm.selfServiceUser
    },
    on: {
      "dealChosen": _vm.setDeal
    }
  }), _vm.loaded ? _c('Content', {
    attrs: {
      "selectedDealId": _vm.dealId
    }
  }) : _vm._e(), _c('Footer', {
    attrs: {
      "id": "self-service-footer",
      "mobileView": _vm.mobileView,
      "broker": _vm.broker
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }