<template>
  <div class="top-row">
    <!--    <v-btn>+</v-btn>
    <v-btn class="no-outline"
      ><img src="../assets/images/bell.svg" alt=""
    /></v-btn>-->
    <div>
      <div v-if="isPreview" class="back-button" @click="returnToProject">
        <img src="../assets/images/chevron-left.svg" alt="" />
        <p>{{ $t("Back") }}</p>
      </div>
    </div>
    <div v-if="!isPreview">
      <v-btn @click="changeLocale('et')">et</v-btn>
      <v-btn @click="changeLocale('en')">en</v-btn>
      <v-btn @click="logOut()" name="logout-button">{{ $t("Logout") }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Cookies from "js-cookie";
export default {
  name: "SelfServiceTopbar",
  props: ["isPreview"],
  computed: {
    /*  showLogout() {
      return (
        document.cookie
          .match("(^|;)\\s*" + "token" + "\\s*=\\s*([^;]+)")
          ?.pop() ?? false
      );
    }, */
  },
  methods: {
    ...mapActions(["logoutSelfUser", "setLanguage"]),
    returnToProject() {
      this.$router
        .push({
          name: "ProjectGeneral",
          params: { id: this.$route.query.dealId },
        })
        .then(() => {});
    },
    logOut() {
      this.logoutSelfUser();
      this.$router.push("/self-service/login");
      Cookies.remove("token");
    },
    changeLocale(loc) {
      this.setLanguage(loc);
      this.$i18n.locale = loc;
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 64px;
  padding-right: 88px;
  padding-top: 24px;
  padding-bottom: 12px;
  background-color: white;
}
.v-btn {
  margin-left: 12px !important;
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
}
.no-outline {
  box-shadow: none;
}
.back-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0 4px;
  }
}
</style>
