<template>
  <div :class="{
    'self-service-footer': 1,
    'footer-desktop': !mobileView,
    'footer-mobile': mobileView,
  }">

    <div v-if="broker" class="footer-broker-card">
      <div v-if="broker[0].profilePicture !== null" class="broker-img">
        <img class="broker-img-src" :src="broker[0].profilePicture.name || broker[0].profilePicture[0].name"/>
      </div>
      <div v-else class="empty-broker-img"></div>
      <div class="footer-broker-info">
        <div class="broker-info-text">
          <span class="broker-name">
            {{broker[0].name}}
          </span>
            <div class="broker-contact"
                 @click.prevent="callNumber">
              <span>
               {{broker[0].phoneNumber}}
             </span>
           </div>
          <div class="broker-contact"
          @click.prevent="sendEmail">
             <span>
               {{broker[0].email}}
              </span>
          </div>
        </div>
      </div>
    </div>

    <div class="send-message-button" style="display: none">
      <img
        src="../../assets/images/chat.svg"
        alt=""
      />
      <div class="content-semibold" style="font-size: 16px;">
        {{ $t("Send") }} {{ $t("Message").toLowerCase() }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: ["mobileView", "activeView", "broker"],
  components: {
  },
  data() {
    return {
    };
  },
  async created() {
    await this.initData();
  },
  mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    async initData() {
    },


    callNumber() {
      window.location.href = `tel:${this.broker[0].phoneNumber}`;
    },
    sendEmail() {
      window.location.href = `mailto:${this.broker[0].email}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.self-service-footer {
  width: 100%;
  height: 148px;
  padding-top: 32px;
  padding-bottom: 48px;
  background: #FFFFFF;
  gap: 24px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.footer-desktop {
  padding-left: 64px;
  padding-right: 312px;
  justify-content: space-between;
}

.footer-mobile {
  height: 140px;
  display: grid;
  padding-bottom: 96px;
  padding-left: 24px;
}

.send-message-button {
  border-radius: 100px;
  border: 2px solid #000000;
  box-sizing: border-box;
  padding: 16px 26px;
  display: flex;
  gap: 16px;
  cursor: pointer;
  inline-size: fit-content;
}
.broker-img {
  max-width: 500px;
  min-height: 68px;
  height: 68px;
  object-fit: cover;
  border-radius: 8px;
}
.broker-img-src{
  height: 100%;
  border-radius: 8px;
}

.empty-broker-img {
  min-width: 80px;
  min-height: 68px;
  width: 80px;
  height: 68px;
  border-radius: 8px;
  background: #939597;
}

.footer-broker-card {
  max-width: 500px;
  height: 68px;
  top: 32px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}
.broker-name{
  top: 0;
  font-family: Geomanist;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}
.broker-contact{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  bottom: 0;
  align-items: end;
  cursor: pointer;
}
.footer-broker-info{
  width: 100%;
  height: 100%;
}
</style>
