var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-container"
  }, [_c('div', {
    staticStyle: {
      "width": "728px",
      "display": "flex",
      "flex-wrap": "wrap"
    }
  }, [_c('div', {
    staticClass: "sales-activity"
  }, [_c('v-row', {
    staticStyle: {
      "padding": "24px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "start"
    }
  }, [_c('img', {
    staticClass: "img20",
    attrs: {
      "src": require("@/assets/images/users.svg")
    }
  }), _c('h5', {
    staticStyle: {
      "padding-left": "12px"
    }
  }, [_vm._v(_vm._s(_vm.$t("SalesActivity")))])])]), _c('div', {
    staticClass: "separator"
  }), _vm.matchingListings && _vm.matchingListings.length === 0 ? _c('v-row', {
    staticClass: "container",
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "margin-top": "24px"
    }
  }, [_vm._v(_vm._s(_vm.$t("NoListingsInProject")))]) : _vm._e(), _vm._l(_vm.matchingListings, function (listing, index) {
    return _c('div', {
      key: listing._id,
      staticClass: "container",
      class: {
        'first-or-middle-listing': index === 0 || index < _vm.matchingListings.length - 1,
        'last-listing': index === _vm.matchingListings.length - 1
      }
    }, [_c('div', {
      staticClass: "listing-name-and-image-container"
    }, [listing.coverImage ? _c('img', {
      staticClass: "listing-image",
      attrs: {
        "src": listing.coverImage,
        "alt": ""
      }
    }) : _vm._e(), _c('v-row', {
      staticClass: "listing-name",
      on: {
        "click": function ($event) {
          return _vm.openListingSidepanel(listing);
        }
      }
    }, [_vm._v(" " + _vm._s(listing.listingName) + " ")])], 1), _c('div', {
      staticStyle: {
        "padding": "8px"
      }
    }, [_c('v-row', {
      staticStyle: {
        "height": "32px",
        "margin-top": "0px",
        "display": "flex",
        "align-items": "center"
      }
    }, [_c('div', {
      staticStyle: {
        "height": "8px",
        "width": "8px",
        "background": "#f4f5f7",
        "border-radius": "8px",
        "margin-right": "8px"
      }
    }), _c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(_vm.$t("Prospects")))]), _c('v-row', {
      staticStyle: {
        "display": "flex",
        "justify-content": "flex-end"
      }
    }, [_c('p', {
      key: _vm.refreshSuggestionsCount,
      staticClass: "font-20-bold"
    }, [_vm._v(" " + _vm._s(listing.suggestionsCount) + " ")])])], 1), _c('div', {
      staticClass: "separator"
    }), _c('v-row', {
      staticStyle: {
        "height": "32px",
        "margin-top": "0px",
        "display": "flex",
        "align-items": "center"
      }
    }, [_c('div', {
      staticStyle: {
        "height": "8px",
        "width": "8px",
        "background-color": "black",
        "border-radius": "8px",
        "margin-right": "8px"
      }
    }), _c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(_vm.$t("OfferedViews")))]), _c('v-row', {
      staticStyle: {
        "display": "flex",
        "justify-content": "flex-end"
      }
    }, [_c('p', {
      staticClass: "font-20-bold"
    }, [_vm._v(" " + _vm._s(listing.funnels.Offers.length) + " ")])])], 1), _c('div', {
      staticClass: "separator"
    }), _c('v-row', {
      staticStyle: {
        "height": "32px",
        "margin-top": "0px",
        "display": "flex",
        "align-items": "center"
      }
    }, [_c('div', {
      staticStyle: {
        "height": "8px",
        "width": "8px",
        "background-color": "#f81ca0",
        "border-radius": "8px",
        "margin-right": "8px"
      }
    }), _c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(_vm.$t("Interested")))]), _c('v-row', {
      staticStyle: {
        "display": "flex",
        "justify-content": "flex-end"
      }
    }, [_c('p', {
      staticClass: "font-20-bold"
    }, [_vm._v(" " + _vm._s(listing.funnels.Interested.length) + " ")])])], 1), _c('div', {
      staticClass: "separator"
    }), _c('v-row', {
      staticStyle: {
        "height": "32px",
        "margin-top": "0px",
        "display": "flex",
        "align-items": "center"
      }
    }, [_c('div', {
      staticStyle: {
        "height": "8px",
        "width": "8px",
        "background-color": "#4c8dd9",
        "border-radius": "8px",
        "margin-right": "8px"
      }
    }), _c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(_vm.$t("WentToSee")))]), _c('v-row', {
      staticStyle: {
        "display": "flex",
        "justify-content": "flex-end"
      }
    }, [_c('p', {
      staticClass: "font-20-bold"
    }, [_vm._v(" " + _vm._s(listing.funnels.Viewed.length) + " ")])])], 1), _c('div', {
      staticClass: "separator"
    }), _c('v-row', {
      staticStyle: {
        "height": "32px",
        "margin-top": "0px",
        "display": "flex",
        "align-items": "center"
      }
    }, [_c('div', {
      staticStyle: {
        "height": "8px",
        "width": "8px",
        "background-color": "#0aaf60",
        "border-radius": "8px",
        "margin-right": "8px"
      }
    }), _c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(_vm.$t("NegotitationPhase")))]), _c('v-row', {
      staticStyle: {
        "display": "flex",
        "justify-content": "flex-end"
      }
    }, [_c('p', {
      staticClass: "font-20-bold"
    }, [_vm._v(" " + _vm._s(listing.funnels["Negotiation & Contract"].length) + " ")])])], 1)], 1)]);
  })], 2), _c('div', {
    staticClass: "client-days-container",
    staticStyle: {
      "height": "fit-content",
      "padding-bottom": "2px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "padding": "24px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "start"
    }
  }, [_c('img', {
    staticClass: "img20",
    attrs: {
      "src": require("@/assets/images/calendar.svg")
    }
  }), _c('h5', {
    staticStyle: {
      "padding-left": "12px"
    }
  }, [_vm._v(_vm._s(_vm.$t("ClientDays")))])])]), _c('div', {
    staticClass: "separator"
  }), _vm.matchingTasks && _vm.matchingTasks.length >= 1 ? _c('v-row', {
    staticStyle: {
      "padding": "24px 24px 0 24px",
      "display": "flex"
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("NumberOfClientDays")))]), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end",
      "align-items": "center",
      "height": "32px"
    }
  }, [_c('p', {
    staticClass: "font-20-bold"
  }, [_vm._v(_vm._s(_vm.matchingTasks.length))])]), _c('div', {
    staticClass: "separator"
  })], 1) : _vm.matchingTasks && _vm.matchingTasks.length >= 1 ? _c('v-row', {
    staticStyle: {
      "padding": "0 24px 24px 24px",
      "display": "flex",
      "padding-bottom": "20px"
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("NumberOfParticipants")))]), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end",
      "align-items": "center",
      "height": "32px"
    }
  }, [_c('p', {
    staticClass: "font-20-bold"
  }, [_vm._v(_vm._s(_vm.numberOfParticipants))])]), _c('div', {
    staticClass: "separator"
  })], 1) : _c('div', {
    staticStyle: {
      "padding": "24px",
      "justify-content": "center",
      "display": "flex",
      "height": "104px",
      "align-items": "center"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("NoClientDaysInProject")) + " ")])]), _vm._l(_vm.matchingTasks, function (task) {
    return _c('div', {
      key: task._id,
      staticClass: "clientdays-container"
    }, [task.dates.startDate ? _c('v-row', {
      staticStyle: {
        "font-family": "Inter",
        "font-style": "normal",
        "font-weight": "600",
        "font-size": "10px",
        "line-height": "16px",
        "text-transform": "uppercase",
        "color": "#75787a"
      }
    }, [_vm._v(_vm._s(_vm.formatDate(task.dates.startDate)) + " "), task.dates.startTime || task.dates.endTime ? _c('img', {
      staticClass: "point-separator",
      staticStyle: {
        "margin-left": "4px",
        "margin-right": "4px",
        "filter": "invert(50%) sepia(4%) saturate(266%) hue-rotate(161deg)"
      },
      attrs: {
        "src": require("@/assets/images/•.svg"),
        "alt": ""
      }
    }) : _vm._e(), task.dates.startTime ? _c('p', {
      staticStyle: {
        "color": "#75787a"
      }
    }, [_vm._v(" " + _vm._s(task.dates.startTime) + " ")]) : _vm._e(), task.dates.startTime && task.dates.endTime ? _c('p', {
      staticStyle: {
        "color": "#75787a"
      }
    }, [_vm._v(" - ")]) : _vm._e(), task.dates.endTime ? _c('p', {
      staticStyle: {
        "color": "#75787a"
      }
    }, [_vm._v(" " + _vm._s(task.dates.endTime) + " ")]) : _vm._e()]) : _vm._e()], 1);
  })], 2), _c('div', {
    staticClass: "client-days-container",
    staticStyle: {
      "min-width": "240px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "padding": "24px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/sticky-note.svg"),
      "alt": ""
    }
  }), _c('h5', {
    staticStyle: {
      "padding-left": "12px"
    }
  }, [_vm._v(_vm._s(_vm.$t("Notes")))])])]), _c('div', {
    staticClass: "separator"
  }), _vm.publicNotes && _vm.publicNotes.length === 0 ? _c('div', {
    staticStyle: {
      "padding": "24px",
      "justify-content": "center",
      "display": "flex",
      "height": "104px",
      "align-items": "center"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("NoPublicNotes")) + " ")])]) : _c('div', {
    staticClass: "task-wrap-section"
  }, _vm._l(_vm.publicNotes, function (note) {
    return _c('div', {
      key: note.content,
      staticClass: "task-wrap-note",
      staticStyle: {
        "display": "flex",
        "min-height": "48px",
        "padding": "8px 6px 4px 6px",
        "background-color": "#e5e8ec",
        "border-radius": "3px"
      }
    }, [_c('p', [_vm._v(_vm._s(note.content))]), _c('div', {
      staticStyle: {
        "text-align": "right"
      }
    }, [_c('p', {
      staticStyle: {
        "color": "grey",
        "font-size": "12px"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatNoteDate(note.createdAt)) + " ")])])]);
  }), 0)], 1)]), _c('div', {
    staticClass: "marketing-container"
  }, [_c('div', {
    staticClass: "marketing-costs"
  }, [_c('v-row', {
    staticStyle: {
      "padding": "24px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "start"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/ads_20_20.svg")
    }
  }), _c('h5', {
    staticStyle: {
      "padding-left": "12px"
    }
  }, [_vm._v(_vm._s(_vm.$t("MarketingCosts")))])])]), _c('div', {
    staticClass: "separator"
  }), _vm.marketingExpenses && _vm.marketingExpenses.length <= 0 ? _c('div', {
    staticStyle: {
      "padding": "24px",
      "justify-content": "center",
      "display": "flex",
      "height": "104px",
      "align-items": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("NoMarketingExpenses")) + " ")]) : _vm._e(), _c('div', {
    staticStyle: {
      "padding": "24px"
    },
    attrs: {
      "id": "main-cont"
    }
  }, [_c('div', {
    staticClass: "main",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm.marketingExpenses && _vm.marketingExpenses.length >= 1 ? _c('v-row', {
    staticStyle: {
      "padding": "0",
      "display": "flex",
      "height": "fit-content",
      "justify-content": "flex-start",
      "align-items": "flex-start",
      "align-content": "flex-start",
      "flex-direction": "column"
    }
  }, [_c('v-row', {
    staticStyle: {
      "height": "16px",
      "margin-top": "0px",
      "display": "flex",
      "align-items": "center",
      "width": "max-content"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "8px",
      "width": "8px",
      "background": "#4c8dd9",
      "border-radius": "8px",
      "margin-right": "8px"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("ForeignMedia")))]), _c('v-row', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('img', {
    staticClass: "point-separator",
    staticStyle: {
      "margin-left": "6px",
      "margin-right": "6px"
    },
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _vm.totalCost > 0 ? _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s((_vm.outdoorSellingPrice / _vm.totalCost * 100).toFixed(2)) + "% ")]) : _c('p', {
    staticClass: "content-small"
  }, [_vm._v("0%")])])], 1)]), _c('v-row', {
    staticStyle: {
      "height": "16px",
      "margin-top": "4px",
      "display": "flex",
      "align-items": "center",
      "width": "max-content"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "8px",
      "width": "8px",
      "background": "#0aaf60",
      "border-radius": "8px",
      "margin-right": "8px"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("SocialMedia")))]), _c('v-row', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('img', {
    staticClass: "point-separator",
    staticStyle: {
      "margin-left": "6px",
      "margin-right": "6px"
    },
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _vm.totalCost > 0 ? _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s((_vm.socialMediaSellingPrice / _vm.totalCost * 100).toFixed(2)) + "% ")]) : _c('p', {
    staticClass: "content-small"
  }, [_vm._v("0%")])])], 1)]), _c('v-row', {
    staticStyle: {
      "height": "16px",
      "margin-top": "4px",
      "display": "flex",
      "align-items": "center",
      "width": "max-content"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "8px",
      "width": "8px",
      "background": "#16afb9",
      "border-radius": "8px",
      "margin-right": "8px"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("LevelService")))]), _c('v-row', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('img', {
    staticClass: "point-separator",
    staticStyle: {
      "margin-left": "6px",
      "margin-right": "6px"
    },
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _vm.totalCost > 0 ? _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s((_vm.printSellingPrice / _vm.totalCost * 100).toFixed(2)) + "% ")]) : _c('p', {
    staticClass: "content-small"
  }, [_vm._v("0%")])])], 1)]), _c('v-row', {
    staticStyle: {
      "height": "16px",
      "margin-top": "4px",
      "display": "flex",
      "align-items": "center",
      "width": "max-content"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "8px",
      "width": "8px",
      "background": "#2b7b6d",
      "border-radius": "8px",
      "margin-right": "8px"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t("Other")))]), _c('v-row', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('img', {
    staticClass: "point-separator",
    staticStyle: {
      "margin-left": "6px",
      "margin-right": "6px"
    },
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _vm.totalCost > 0 ? _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s((_vm.onlineSellingPrice / _vm.totalCost * 100).toFixed(2)) + "% ")]) : _c('p', {
    staticClass: "content-small"
  }, [_vm._v("0%")])])], 1)])], 1) : _vm._e(), _vm.deal ? _c('div', {
    key: _vm.chartUpdater,
    staticClass: "canvas"
  }, [_c('canvas', {
    staticStyle: {
      "display": "initial"
    },
    attrs: {
      "width": "132",
      "height": "132",
      "id": "myChart"
    }
  })]) : _vm._e()], 1), _vm.deal ? _c('div', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('MarketingExpenses', {
    key: _vm.refreshExpenses,
    attrs: {
      "showDetailButton": true,
      "dailyCostHidden": true,
      "hideFileAdding": true,
      "hideUploadAndDelete": true,
      "dateFormat": true,
      "selfServicePriceFormat": true,
      "project-id": _vm.deal._id
    },
    on: {
      "getExpensesValues": _vm.marketingValues
    }
  })], 1) : _vm._e()])], 1)]), _vm.listingSidepanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-id": _vm.clickedListingId,
      "client-report-page": true,
      "hidden-marketplaces": true,
      "mobile-view": _vm.mobileView
    },
    on: {
      "overlayClicked": _vm.closeListingSidepanel
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }