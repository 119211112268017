<template>
  <div class="header">
    <v-row class="first-row">
      <h3 v-if="isPreview" class="header-title">
        {{ $t("ClientReport") }}
      </h3>
      <h3 v-if="deal && !isPreview" class="header-title">{{ deal.name }}</h3>
      <h3 v-if="!deal && deals && !isPreview" class="header-title">
        {{ deals[0].name }}
      </h3>

      <div
        v-if="isPreview && projectClient.isPublished"
        class="published-status"
      >
        <p>{{ $t("Published") }}</p>
      </div>
      <div v-if="isPreview && !projectClient.isPublished" style="height: 34px">
        <button
          name="self-service-button"
          class="self-service-button"
          color="blue darken-1"
          text
          @click="sendInvitation()"
        >
          {{ $t("Publish") }}
        </button>
      </div>
    </v-row>
    <div v-if="!isPreview" style="padding-top: 12px">
      <Dropdown
        v-if="deals"
        :items="deals"
        :display-key="'name'"
        :value-key="'_id'"
        :default-item="deals[0]"
        :selected="deals[0]"
        @itemSelected="getDealListings"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/common/Dropdown";
import axios from "axios";
import { mapGetters } from "vuex";
//import axios from 'axios';
export default {
  name: "SelfServiceHeader",
  components: {
    Dropdown,
  },
  props: ["deals", "isPreview", "projectClient"],

  data() {
    return {
      filtersOpen: false,
      deal: null,
    };
  },
  created() {
    this.checkForSelectedItem();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    checkForSelectedItem() {
      if (!this.deal && this.deals) {
        this.deal = this.deals[0];
      }
    },
    async getDealListings(selectedItem) {
      let dealId = null;
      dealId = selectedItem._id;
      this.deals.forEach((deal) => {
        if (deal._id === dealId) {
          this.deal = deal;
        }
      });
      this.$emit("dealChosen", dealId);
    },
    async sendInvitation() {
      const userToPost = {
        name: this.projectClient.contactName,
        contactId: this.projectClient.contactId,
        email: this.projectClient.email,
        companyId: this.user.companyId,
      };
      await axios
        .post("/api/project/self-service/create-user", userToPost)
        .then((res) => {
          if (res.status === 201) {
            window.location.reload();
            this.toastSuccess(this.$t("SelfServiceUserAdded"));
          } else if (res.status === 422) {
            this.toastError(this.$t("PleaseAddEmailToProjectContact"));
          } else if (res.status === 409) {
            this.toastError(this.$t("UserAlreadyAdded"));
          } else {
            this.toastError(this.$t("ErrorAdding"));
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: white;
  display: block;
  padding-left: 64px;
  padding-bottom: 24px;
}
.first-row {
  align-items: center;
}
.published-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #0aaf60;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 24px;
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0aaf60;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0 8px;
  }
}
.self-service-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  font-size: 14px;
  height: 32px;
  padding: 8px 12px;
  font-family: "Inter", sans-serif;
  text-transform: none;
  border-radius: 8px;
}
::v-deep .default-item {
  color: #000000;
}
@media screen and (max-width: 428px) {
  .header {
    padding-left: 24px;
    padding-top: 36px;
  }
}
</style>
