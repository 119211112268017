<template>
  <div>
    <SelfServiceTopBar :is-preview="isPreview" />
    <Header
      :deals="deals"
      @dealChosen="setDeal"
      :is-preview="isPreview"
      :project-client="selfServiceUser"
    />
    <Content v-if="loaded" :selectedDealId="dealId" />
      <Footer
          id="self-service-footer"
          :mobileView="mobileView"
          :broker="broker"

      />
  </div>
</template>

<script>
import SelfServiceTopBar from "@/components/SelfServiceTopBar.vue";
import Header from "@/components/SelfService/Header.vue";
import Content from "@/components/SelfService/Content.vue";
import Footer from "@/components/SelfService/Footer";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "SelfServiceBoard",
  components: {
    Header,
    Content,
    SelfServiceTopBar,
    Footer,
  },
  data() {
    return {
      deals: null,
      dealId: null,
      loaded: false,
      mobileView: false,
      broker: null,
      brokerId: [],
    };
  },
  async created() {
    this.mobileView = window.innerWidth < 1000;
    window.addEventListener('resize', () => this.mobileView = window.innerWidth < 1000);
    await this.getAllProjectsAndListingsInside();
    await this.initData();
  },
  mounted() {},
  computed: {
    ...mapGetters(["selfServiceUser", "customers", "users", "user"]),
    isPreview() {
      return this.$route.query.dealId;
    },
  },
  methods: {
    async initData(){
      await this.initBroker();
    },

    async initBroker(){
      let customerResp = await axios.post(`/api/contact/public/custom/${this.selfServiceUser.contactId}`);
      let brokerId = [];
      brokerId.push(customerResp.data.creator.userId);
      let response = await axios.post('/api/users-preview', brokerId);
      this.broker = response.data;
    },

    async getAllProjectsAndListingsInside() {
      if (!this.selfServiceUser) {
        this.$router.push("/self-service/login");
        return;
      } else {
        let contactId = this.selfServiceUser.contactId;
        const response = await axios.get(
          `/api/self-service/connected-listings/${contactId}`
        );
        this.dealId = this.$route.query.dealId;
        this.deals = response.data;
        this.loaded = true;
        this.deals = this.deals.reverse();
      }
    },
    setDeal(dealId) {
      this.dealId = dealId;
    },
  }
};
</script>

<style lang="scss" scoped>

.selfService-footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 200px;
}
</style>
