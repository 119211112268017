<template>
  <div class="main-container">
    <div style="width: 728px; display: flex; flex-wrap: wrap">
      <div class="sales-activity">
        <v-row style="padding: 24px">
          <div style="display: flex; align-items: start">
            <img src="@/assets/images/users.svg" class="img20" />
            <h5 style="padding-left: 12px">{{ $t("SalesActivity") }}</h5>
          </div>
        </v-row>
        <div class="separator"></div>
        <v-row
          class="container"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 24px;
          "
          v-if="matchingListings && matchingListings.length === 0"
          >{{ $t("NoListingsInProject") }}</v-row
        >

        <div
          v-for="(listing, index) in matchingListings"
          :key="listing._id"
          class="container"
          v-bind:class="{
            'first-or-middle-listing':
              index === 0 || index < matchingListings.length - 1,
            'last-listing': index === matchingListings.length - 1,
          }"
        >
          <div class="listing-name-and-image-container">
            <img
              v-if="listing.coverImage"
              class="listing-image"
              :src="listing.coverImage"
              alt=""
            />
            <v-row class="listing-name" @click="openListingSidepanel(listing)">
              {{ listing.listingName }}
            </v-row>
          </div>
          <div style="padding: 8px">
            <v-row
              style="
                height: 32px;
                margin-top: 0px;
                display: flex;
                align-items: center;
              "
            >
              <div
                style="
                  height: 8px;
                  width: 8px;
                  background: #f4f5f7;
                  border-radius: 8px;
                  margin-right: 8px;
                "
              ></div>
              <p class="content-small">{{ $t("Prospects") }}</p>
              <v-row style="display: flex; justify-content: flex-end"
                ><p class="font-20-bold" :key="refreshSuggestionsCount">
                  {{ listing.suggestionsCount }}
                </p></v-row
              >
            </v-row>
            <div class="separator"></div>

            <v-row
              style="
                height: 32px;
                margin-top: 0px;
                display: flex;
                align-items: center;
              "
            >
              <div
                style="
                  height: 8px;
                  width: 8px;
                  background-color: black;
                  border-radius: 8px;
                  margin-right: 8px;
                "
              ></div>
              <p class="content-small">{{ $t("OfferedViews") }}</p>
              <v-row style="display: flex; justify-content: flex-end"
                ><p class="font-20-bold">
                  {{ listing.funnels.Offers.length }}
                </p></v-row
              >
            </v-row>
            <div class="separator"></div>
            <v-row
              style="
                height: 32px;
                margin-top: 0px;
                display: flex;
                align-items: center;
              "
            >
              <div
                style="
                  height: 8px;
                  width: 8px;
                  background-color: #f81ca0;
                  border-radius: 8px;
                  margin-right: 8px;
                "
              ></div>
              <p class="content-small">{{ $t("Interested") }}</p>
              <v-row style="display: flex; justify-content: flex-end"
                ><p class="font-20-bold">
                  {{ listing.funnels.Interested.length }}
                </p></v-row
              >
            </v-row>
            <div class="separator"></div>

            <v-row
              style="
                height: 32px;
                margin-top: 0px;
                display: flex;
                align-items: center;
              "
            >
              <div
                style="
                  height: 8px;
                  width: 8px;
                  background-color: #4c8dd9;
                  border-radius: 8px;
                  margin-right: 8px;
                "
              ></div>
              <p class="content-small">{{ $t("WentToSee") }}</p>
              <v-row style="display: flex; justify-content: flex-end"
                ><p class="font-20-bold">
                  {{ listing.funnels.Viewed.length }}
                </p></v-row
              ></v-row
            >
            <div class="separator"></div>

            <v-row
              style="
                height: 32px;
                margin-top: 0px;
                display: flex;
                align-items: center;
              "
            >
              <div
                style="
                  height: 8px;
                  width: 8px;
                  background-color: #0aaf60;
                  border-radius: 8px;
                  margin-right: 8px;
                "
              ></div>
              <p class="content-small">{{ $t("NegotitationPhase") }}</p>
              <v-row style="display: flex; justify-content: flex-end"
                ><p class="font-20-bold">
                  {{ listing.funnels["Negotiation & Contract"].length }}
                </p></v-row
              ></v-row
            >
          </div>
        </div>
      </div>
      <div
        class="client-days-container"
        style="height: fit-content; padding-bottom: 2px"
      >
        <v-row style="padding: 24px">
          <div style="display: flex; align-items: start">
            <img src="@/assets/images/calendar.svg" class="img20" />
            <h5 style="padding-left: 12px">{{ $t("ClientDays") }}</h5>
          </div>
        </v-row>
        <div class="separator"></div>
        <v-row
          v-if="matchingTasks && matchingTasks.length >= 1"
          style="padding: 24px 24px 0 24px; display: flex"
        >
          <p class="content-small">{{ $t("NumberOfClientDays") }}</p>
          <v-row
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
              height: 32px;
            "
            ><p class="font-20-bold">{{ matchingTasks.length }}</p></v-row
          >
          <div class="separator"></div>
        </v-row>
        <v-row
          v-else-if="matchingTasks && matchingTasks.length >= 1"
          style="padding: 0 24px 24px 24px; display: flex; padding-bottom: 20px"
        >
          <p class="content-small">{{ $t("NumberOfParticipants") }}</p>
          <v-row
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
              height: 32px;
            "
            ><p class="font-20-bold">{{ numberOfParticipants }}</p></v-row
          >
          <div class="separator"></div>
        </v-row>
        <div
          v-else
          style="
            padding: 24px;
            justify-content: center;
            display: flex;
            height: 104px;
            align-items: center;
          "
        >
          <p>
            {{ $t("NoClientDaysInProject") }}
          </p>
        </div>

        <div
          v-for="task in matchingTasks"
          :key="task._id"
          class="clientdays-container"
        >
          <v-row
            v-if="task.dates.startDate"
            style="
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 10px;
              line-height: 16px;
              text-transform: uppercase;
              color: #75787a;
            "
            >{{ formatDate(task.dates.startDate) }}
            <img
              v-if="task.dates.startTime || task.dates.endTime"
              style="
                margin-left: 4px;
                margin-right: 4px;
                filter: invert(50%) sepia(4%) saturate(266%) hue-rotate(161deg)
                  brightness(92%) contrast(88%);
              "
              class="point-separator"
              src="@/assets/images/•.svg"
              alt=""
            />
            <p style="color: #75787a" v-if="task.dates.startTime">
              {{ task.dates.startTime }}
            </p>
            <p
              style="color: #75787a"
              v-if="task.dates.startTime && task.dates.endTime"
            >
              -
            </p>
            <p style="color: #75787a" v-if="task.dates.endTime">
              {{ task.dates.endTime }}
            </p></v-row
          >
        </div>
      </div>
      <div class="client-days-container" style="min-width: 240px">
        <v-row style="padding: 24px">
          <div style="display: flex; align-items: center">
            <img src="@/assets/images/sticky-note.svg" alt="" />
            <h5 style="padding-left: 12px">{{ $t("Notes") }}</h5>
          </div>
        </v-row>
        <div class="separator"></div>

        <div
          v-if="publicNotes && publicNotes.length === 0"
          style="
            padding: 24px;
            justify-content: center;
            display: flex;
            height: 104px;
            align-items: center;
          "
        >
          <p>
            {{ $t("NoPublicNotes") }}
          </p>
        </div>

        <div class="task-wrap-section" v-else>
          <div
            v-for="note in publicNotes"
            :key="note.content"
            class="task-wrap-note"
            style="
              display: flex;
              min-height: 48px;

              padding: 8px 6px 4px 6px;
              background-color: #e5e8ec;
              border-radius: 3px;
            "
          >
            <p>{{ note.content }}</p>
            <div style="text-align: right">
              <p style="color: grey; font-size: 12px">
                {{ formatNoteDate(note.createdAt) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="marketing-container">
      <div class="marketing-costs">
        <v-row style="padding: 24px">
          <div style="display: flex; align-items: start">
            <img src="@/assets/images/ads_20_20.svg" />
            <h5 style="padding-left: 12px">{{ $t("MarketingCosts") }}</h5>
          </div>
        </v-row>
        <div class="separator"></div>
        <div
          style="
            padding: 24px;
            justify-content: center;
            display: flex;
            height: 104px;
            align-items: center;
          "
          v-if="marketingExpenses && marketingExpenses.length <= 0"
        >
          {{ $t("NoMarketingExpenses") }}
        </div>
        <div id="main-cont" style="padding: 24px">
          <div class="main" style="display: flex; align-items: center">
            <v-row
              v-if="marketingExpenses && marketingExpenses.length >= 1"
              style="
                padding: 0;
                display: flex;
                height: fit-content;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                flex-direction: column;
              "
            >
              <v-row
                style="
                  height: 16px;
                  margin-top: 0px;
                  display: flex;
                  align-items: center;
                  width: max-content;
                "
              >
                <div
                  style="
                    height: 8px;
                    width: 8px;
                    background: #4c8dd9;
                    border-radius: 8px;
                    margin-right: 8px;
                  "
                ></div>
                <div style="display: flex">
                  <p class="content-small">{{ $t("ForeignMedia") }}</p>
                  <v-row style="display: flex">
                    <img
                      style="margin-left: 6px; margin-right: 6px"
                      class="point-separator"
                      src="@/assets/images/•.svg"
                      alt=""
                    />
                    <p v-if="totalCost > 0" class="content-small">
                      {{
                        ((outdoorSellingPrice / totalCost) * 100).toFixed(2)
                      }}%
                    </p>
                    <p v-else class="content-small">0%</p></v-row
                  >
                </div>
              </v-row>
              <v-row
                style="
                  height: 16px;
                  margin-top: 4px;
                  display: flex;
                  align-items: center;
                  width: max-content;
                "
              >
                <div
                  style="
                    height: 8px;
                    width: 8px;
                    background: #0aaf60;
                    border-radius: 8px;
                    margin-right: 8px;
                  "
                ></div>
                <div style="display: flex">
                  <p class="content-small">{{ $t("SocialMedia") }}</p>
                  <v-row style="display: flex">
                    <img
                      style="margin-left: 6px; margin-right: 6px"
                      class="point-separator"
                      src="@/assets/images/•.svg"
                      alt=""
                    />
                    <p v-if="totalCost > 0" class="content-small">
                      {{
                        ((socialMediaSellingPrice / totalCost) * 100).toFixed(
                          2
                        )
                      }}%
                    </p>
                    <p v-else class="content-small">0%</p></v-row
                  >
                </div>
              </v-row>
              <v-row
                style="
                  height: 16px;
                  margin-top: 4px;
                  display: flex;
                  align-items: center;
                  width: max-content;
                "
              >
                <div
                  style="
                    height: 8px;
                    width: 8px;
                    background: #16afb9;
                    border-radius: 8px;
                    margin-right: 8px;
                  "
                ></div>
                <div style="display: flex">
                  <p class="content-small">{{ $t("LevelService") }}</p>
                  <v-row style="display: flex">
                    <img
                      style="margin-left: 6px; margin-right: 6px"
                      class="point-separator"
                      src="@/assets/images/•.svg"
                      alt=""
                    />
                    <p v-if="totalCost > 0" class="content-small">
                      {{ ((printSellingPrice / totalCost) * 100).toFixed(2) }}%
                    </p>
                    <p v-else class="content-small">0%</p></v-row
                  >
                </div>
              </v-row>
              <v-row
                style="
                  height: 16px;
                  margin-top: 4px;
                  display: flex;
                  align-items: center;
                  width: max-content;
                "
              >
                <div
                  style="
                    height: 8px;
                    width: 8px;
                    background: #2b7b6d;
                    border-radius: 8px;
                    margin-right: 8px;
                  "
                ></div>
                <div style="display: flex">
                  <p class="content-small">{{ $t("Other") }}</p>
                  <v-row style="display: flex">
                    <img
                      style="margin-left: 6px; margin-right: 6px"
                      class="point-separator"
                      src="@/assets/images/•.svg"
                      alt=""
                    />
                    <p v-if="totalCost > 0" class="content-small">
                      {{ ((onlineSellingPrice / totalCost) * 100).toFixed(2) }}%
                    </p>
                    <p v-else class="content-small">0%</p></v-row
                  >
                </div>
              </v-row>
            </v-row>
            <div v-if="deal" :key="chartUpdater" class="canvas">
              <canvas
                style="display: initial"
                width="132"
                height="132"
                id="myChart"
              ></canvas>
            </div>
          </div>
          <div style="margin-top: 20px" v-if="deal">
            <MarketingExpenses
              :key="refreshExpenses"
              :showDetailButton="true"
              :dailyCostHidden="true"
              :hideFileAdding="true"
              :hideUploadAndDelete="true"
              :dateFormat="true"
              :selfServicePriceFormat="true"
              :project-id="deal._id"
              @getExpensesValues="marketingValues"
            />
          </div>
        </div>
      </div>
    </div>
    <ListingSidepanel
      @overlayClicked="closeListingSidepanel"
      v-if="listingSidepanelOpen"
      :listing-id="clickedListingId"
      :client-report-page="true"
      :hidden-marketplaces="true"
      :mobile-view="mobileView"
    />
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import MarketingExpenses from "@/components/common/MarketingExpenses";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
import Chart from "chart.js/auto";
import { Doughnut } from "vue-chartjs";
import moment from "moment";

export default {
  extends: Doughnut,
  name: "App",
  components: {
    ListingSidepanel,
    MarketingExpenses,
  },
  props: ["selectedDealId"],
  //now need to sort deals list by selectedDealId?

  data() {
    return {
      mobileView: false,
      clickedListingId: null,
      listingSidepanelOpen: false,
      coverImage: [],
      numberOfParticipants: 0,
      listingData: null,
      deals: null,
      matchingListings: null,
      matchingTasks: null,
      deal: null,
      socialMediaCounter: 0,
      socialMediaSellingPrice: 0,
      outdoorCounter: 0,
      outdoorSellingPrice: 0,
      printCounter: 0,
      printSellingPrice: 0,
      onlineCounter: 0,
      onlineSellingPrice: 0,
      totalCounter: 0,
      refreshExpenses: 0,
      refreshSuggestionsCount: 0,
      marketingExpenses: null,
      ctx: null,
      totalCost: 0,
      chartUpdater: 0,
      publicNotes: [],
    };
  },
  created() {
    this.mobileView = window.innerWidth < 1000;
    window.addEventListener(
      "resize",
      () => (this.mobileView = window.innerWidth < 1000)
    );
    this.getSelfServiceUser();
  },
  mounted() {},
  watch: {
    selectedDealId() {
      this.getAllProjectsAndListingsInside();
      this.getPublicNotes();
      this.chartUpdater++;
    },
  },
  computed: {
    ...mapGetters(["selfServiceUser"]),
  },
  methods: {
    openListingSidepanel(listing) {
      this.clickedListingId = listing._id;
      this.listingSidepanelOpen = true;
    },
    closeListingSidepanel() {
      this.listingSidepanelOpen = false;
    },
    async getSelfServiceUser() {
      await this.getAllProjectsAndListingsInside();
      this.getPublicNotes();
      this.createChart();
    },
    formatDate(date) {
      let weekDay = this.$t(moment(date).format("dddd"));
      return weekDay + ", " + moment(date).format("DD.MM.YYYY");
    },
    formatNoteDate(date) {
      const formatDate = moment(date).format("DD.MM.YYYY");
      if (formatDate === "Invalid date") {
        date = date.replaceAll("/", ".");
        return date;
      } else {
        return formatDate;
      }
    },
    async getParticipants() {
      this.numberOfParticipants = null;

      let id = this.deal._id;
      const respo = await axios.get(`/api/self-service/tasks/user/all/${id}`);
      this.matchingTasks = respo.data;

      for (let i = 0; i < this.matchingTasks.length; i++) {
        if (this.matchingTasks[i].type === "open_house") {
          if (
            this.matchingTasks[i].numberOfParticipants > 0 &&
            this.matchingTasks[i].numberOfParticipants !== undefined
          ) {
            this.numberOfParticipants +=
              this.matchingTasks[i].numberOfParticipants;
          }
        }
      }
    },
    async getTasks() {
      let id = this.deal._id;
      const respo = await axios.get(`/api/self-service/tasks/user/all/${id}`);
      this.matchingTasks = respo.data;
    },

    async createChart() {
      if (this.deal) {
        this.ctx = document.getElementById("myChart");
        const stars = [
          this.onlineSellingPrice,
          this.socialMediaSellingPrice,
          this.printSellingPrice,
          this.outdoorSellingPrice,
        ];
        //eslint-disable-next-line
        new Chart(this.ctx, {
          type: "doughnut",
          data: {
            datasets: [
              {
                label: "Github Stars",
                data: stars,
                backgroundColor: ["#2b7b6d", "#0aaf60", "#16afb9", "#4c8dd9"],
                borderColor: "#FFFFFF",
                borderWidth: 1,
                borderRadius: 4,
              },
            ],
          },
          options: {
            percentageInnerCutout: 10,
            spacing: 1,
            cutout: 35,
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return " " + context.parsed + " €";
                  },
                },
              },
              customPlugin: {
                consoleText: this.totalCost + " €",
              },
            },
          },
          plugins: [
            {
              id: "customPlugin",
              beforeDraw: (chart, args, options) => {
                var width = chart.width;
                var height = chart.height;
                var ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 186).toFixed(2);
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "middle";
                let text = options.consoleText;
                var textX = Math.round(
                  (width - ctx.measureText(text).width) / 2
                );
                var textY = height / 1.83;
                ctx.fillText(text, textX, textY);

                ctx.save();
              },
            },
          ],
        });
      }
    },
    marketingValues(marketingValues) {
      this.marketingExpenses = marketingValues;
    },
    async getAllProjectsAndListingsInside() {
      let contactId = this.selfServiceUser.contactId;
      let listingsArray = [];
      const response = await axios.get(
        `/api/self-service/connected-listings/${contactId}`
      );
      this.deals = response.data.reverse();
      this.deal = this.deals[0];
      this.totalCost = 0;
      /*  this.printSellingPrice = 0;
      this.onlineSellingPrice = 0;
      this.outdoorSellingPrice = 0;
      this.socialMediaSellingPrice = 0; */
      if (
        this.deal &&
        (!this.deal.marketingExpenses ||
          this.deal.marketingExpenses.length <= 0)
      ) {
        document.getElementById("main-cont").style.display = "none";
      }
      this.deal.marketingExpenses.forEach((elem) => {
        this.totalCounter++;
        if (elem.type === "Social media") {
          this.socialMediaCounter++;
          this.socialMediaSellingPrice += elem.sellingPrice;
        }
        if (elem.type === "Outdoor") {
          this.outdoorCounter++;
          this.outdoorSellingPrice += elem.sellingPrice;
        }
        if (
          elem.type === "Print" ||
          elem.type === "KV" ||
          elem.type === "KV24" ||
          elem.type === "CITY24"
        ) {
          this.printCounter++;
          this.printSellingPrice += elem.cost;
          this.totalCost += elem.cost;
        }
        if (elem.type === "Online") {
          this.onlineCounter++;
          this.onlineSellingPrice += elem.sellingPrice;
        }
        if (elem.sellingPrice) {
          this.totalCost += elem.sellingPrice;
        }
      });
      if (!this.selectedDealId) {
        this.deals = this.deals[0];
        this.totalCost = 0;
        this.deals.marketingExpenses.forEach((el) => {
          if (el.sellingPrice) {
            this.totalCost += el.sellingPrice;
          }
          if (
            el.type === "KV" ||
            el.type === "KV24" ||
            el.type === "CITY24" ||
            el.type === "Print"
          ) {
            this.totalCost += el.cost;
          }
        });
        this.createChart();
      }
      if (this.selectedDealId) {
        this.deals.forEach((deal) => {
          if (deal._id === this.selectedDealId) {
            this.deals = deal;
            this.deal = deal;

            this.refreshExpenses++;
          }
        });
        this.totalCounter = 0;
        this.socialMediaCounter = 0;
        this.onlineCounter = 0;
        this.printCounter = 0;
        this.outdoorCounter = 0;
        this.totalCost = 0;
        this.onlineSellingPrice = 0;
        this.printSellingPrice = 0;
        this.outdoorSellingPrice = 0;
        this.socialMediaSellingPrice = 0;

        this.deal.marketingExpenses.forEach((elem) => {
          if (elem.sellingPrice) {
            this.totalCost += elem.sellingPrice;
          }
          if (
            elem.type === "KV" ||
            elem.type === "KV24" ||
            elem.type === "CITY24" ||
            elem.type === "Print"
          ) {
            this.totalCost += elem.cost;
          }
          this.totalCounter++;
          if (elem.type === "Social media") {
            this.socialMediaSellingPrice += elem.sellingPrice;

            this.socialMediaCounter++;
          }
          if (elem.type === "Outdoor") {
            this.outdoorSellingPrice += elem.sellingPrice;

            this.outdoorCounter++;
          }
          if (
            elem.type === "Print" ||
            elem.type === "KV" ||
            elem.type === "KV24" ||
            elem.type === "CITY24"
          ) {
            this.printSellingPrice += elem.cost;
            this.printCounter++;
          }
          if (elem.type === "Online") {
            this.onlineSellingPrice += elem.sellingPrice;
            this.onlineCounter++;
          }
        });
        if (
          this.deal &&
          (!this.deal.marketingExpenses ||
            this.deal.marketingExpenses.length <= 0)
        ) {
          document.getElementById("main-cont").style.display = "none";
        } else if (this.deal && this.deal.marketingExpenses.length >= 1) {
          document.getElementById("main-cont").style.display = "block";
          this.createChart();
        }
      }

      /*  if (this.deals.length > 1) {
        this.deals.forEach((deal) => {
          listingsArray.push(deal.objects[0].listings);
        });
        let listingIds = listingsArray.flatMap((x) => x);
        let payload = { listingIds };
        let matchingListings = await axios.post("/api/listings", payload);
        this.matchingListings = matchingListings.data.listings;
      } */
      this.getTasks();
      this.getParticipants();

      listingsArray.push(this.deals.objects[0].listings);
      let listingIds = listingsArray.flatMap((x) => x);
      let payload = { listingIds };
      let matchingListings = await axios.post(
        "/api/self-service/listings",
        payload
      );
      this.matchingListings = matchingListings.data.listings;
      this.setCoverImages(this.matchingListings);
      const resp = await axios.post(
        "/api/listing/auto-suggest/count/all",
        listingIds
      );
      const counts = resp.data;
      this.matchingListings.forEach((matchingListing) => {
        const countExists = Object.prototype.hasOwnProperty.call(
          counts,
          matchingListing._id
        );
        if (countExists) {
          matchingListing.suggestionsCount = counts[matchingListing._id];
        } else {
          matchingListing.suggestionsCount = 0;
        }
      });
      this.refreshSuggestionsCount++;
    },
    setCoverImages(matchingListings) {
      matchingListings.forEach((listing) => {
        listing.images.forEach((image) => {
          if (!image.hidden && !listing.coverImage) {
            listing.coverImage = image.name;
          }
        });
      });
    },
    async getPublicNotes() {
      let id = null;

      if (!this.selectedDealId) {
        let contactId = this.selfServiceUser.contactId;
        const response = await axios.get(
          `/api/self-service/connected-listings/${contactId}`
        );
        this.deals = response.data.reverse();
        id = this.deals[0]._id;
      } else {
        id = this.selectedDealId;
      }

      const response = await axios.get(`/api/self-service/public-notes/${id}`);
      const allNotes = response.data;
      const dealsWithNotes = [];
      const unsortedNotes = [];

      allNotes.forEach((dealWithNotes) => {
        dealsWithNotes.push(dealWithNotes);
      });

      for (let noteSetNr = 0; noteSetNr < dealsWithNotes.length; noteSetNr++) {
        if (
          dealsWithNotes[noteSetNr].isPublic === true &&
          dealsWithNotes[noteSetNr].content
        ) {
          unsortedNotes.push(dealsWithNotes[noteSetNr]);
        }
      }
      this.publicNotes = unsortedNotes.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #f4f5f7;
  padding: 24px 8px 24px 104px;
  max-width: 1504px;
}
.task-wrap {
  max-width: 387px;
  min-width: 370px;
  overflow-y: scroll;
  height: fit-content;
  padding: 24px 24px 0px 24px;
  background-color: white;
  border-radius: 8px;
}
.separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
}
.client-days-container {
  background-color: white;
  width: 340px;
  min-height: 180px;
  border-radius: 8px;
  margin-right: 24px;
  margin-top: 24px;
}

.edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  height: 24px;
  gap: 6px;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 58px;
  //margin-left: 96px;
}
.main-container {
  padding-left: 72px;
  padding-right: 24px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin-bottom: 48px;
}
.first-or-middle-listing {
  padding: 24px 24px 24px;
  gap: 12px;
  border-bottom: 1px solid #e6e8ec;
}
.last-listing {
  padding: 24px 24px 0px;
  gap: 12px;
}
.listing-name-and-image-container {
  margin-bottom: 16px;
  word-break: break-word;
  height: fit-content;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 8px;
}
.listing-image {
  width: 72px;
  height: 48px;
  border-radius: 8px;
}
.listing-name {
  padding: 0 8px 0 16px;
  width: fit-content;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
}
.sales-activity {
  background-color: white;
  width: 80vw;
  max-width: 702px;
  min-width: 344px;
  height: fit-content;
  border-radius: 8px;
  margin-right: 24px;
  margin-top: 24px;
  padding-bottom: 2px;
  flex-shrink: initial;
}
.clientdays-container {
  max-height: 212px;
  padding: 24px;
  padding-bottom: 0px;
  gap: 12px;
  padding-top: 0px;
  margin-top: 4px;
}
.marketing-container {
  min-width: 344px;
}
.marketing-costs {
  margin-top: 24px;
  background-color: white;
  width: 340px;
  border-radius: 8px;
  padding-bottom: 2px;
}
@media screen and (max-width: 428px) {
  .main-container {
    padding-left: 42px;
    display: flex;
    flex-wrap: wrap;
  }
  .sales-activity {
    margin-bottom: 12px;
  }
  .container[data-v-2c564693]:last-child {
    margin-bottom: 60px;
  }
  .marketing-costs {
    margin-top: 16px;
    margin-left: 0px;
    width: -webkit-fill-available;
  }
  .client-days-container {
    margin-left: 0px;
    margin-top: 12px;
    margin-bottom: 12px;
    width: -webkit-fill-available;
  }
}
.label-text {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  font-size: 1.5rem;
  padding-top: 1.5rem;
}
.font-20-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.container:last-child {
  margin-bottom: 24px;
}
.clientdays-container:last-child {
  margin-bottom: 24px;
}

.task-wrap-section {
  padding: 16px;
}

.task-wrap-note {
  flex-direction: column;
  margin: 0px 0px 8px 0px;
}
</style>
